import React from "react";
import { Link } from "gatsby";


const NotFoundPage = () => (
    <div className="notfound">
        <div className="code">404</div>
        <p>
            Looks like this page went on vacation. <br/>
            <Link to="/">Go home..</Link>
        </p>
    </div>
)
export default NotFoundPage;